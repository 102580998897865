import { useReducer, useCallback } from 'react';
import { productPanelReducer } from './productPanelReducer';

const initialState = [];

export const useProductPanel = () => {
  const [viewSimilarProducts, dispatch] = useReducer(productPanelReducer, initialState);

  const setViewSimilarProducts = useCallback((products) => {
    dispatch({ type: 'SET_VIEW_SIMILAR_PRODUCTS', payload: products });
  }, []);

  const updateViewSimilarQuantity = useCallback((productId, quantity) => {
    dispatch({ type: 'UPDATE_QUANTITY', payload: quantity, meta: { productId } });
  }, []);

  const updateViewSimilarSize = useCallback((productId, size) => {
    dispatch({ type: 'SET_SELECTED_SIZE', payload: size, meta: { productId } });
  }, []);

  const updateViewSimilarColor = useCallback((productId, color) => {
    dispatch({ type: 'SET_SELECTED_COLOR', payload: color, meta: { productId } });
  }, []);

  const updateViewSimilarHoveredColor = useCallback((productId, color) => {
    dispatch({ type: 'SET_HOVERED_COLOR', payload: color, meta: { productId } });
  }, []);

  const updateViewSimilarFavorite = useCallback((productId, isFavorite) => {
    dispatch({ type: 'SET_FAVORITE', payload: isFavorite, meta: { productId } });
  }, []);

  const updateAddToBagError = useCallback((productId, error) => {
    dispatch({ type: 'ADD_TO_BAG_ERROR', payload: error, meta: { productId } });
  }, []);

  return {
    viewSimilarProducts,
    setViewSimilarProducts,
    updateViewSimilarQuantity,
    updateViewSimilarSize,
    updateViewSimilarColor,
    updateViewSimilarHoveredColor,
    updateViewSimilarFavorite,
    updateAddToBagError,
  };
};
