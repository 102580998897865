import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { ProductPanel } from '@nmg/nmo-ui-productpanel/build';
import '@nmg/nmo-ui-productpanel/build/index.css';
import axios from 'axios';
import Auth from '@aws-amplify/auth';
import { getRequestParams } from 'profile/components/MyFavorites/MyFavorites';
import { closeProductPanel, toggleProductPanelFavorite } from 'cms/actions';
import { addToBag } from 'pdp/pages/ProductPage/actions';
import { useProductPanel } from './useProductPanel';
import { referralSourceClicked } from 'client/common/actions/actions-page';
import { QUICK_LOOK } from 'plp/constants';
import { removeBodyClass } from 'client/utilities/bodyClass';

const ProductPanelWrapper = (props) => {
  const {
    isProductPanelOpen,
    productPanelData,
    device,
    cloudinaryDprToggle,
    isServeProperSizedScene7PDP,
    onlyXLeftThreshold,
    vendorQtyRestrictions,
    dispatch,
    panelTitle,
    isLoading,
    shouldUseOriginalCanonicalURL,
    session,
    pageId
  } = props;

  const {
    viewSimilarProducts,
    setViewSimilarProducts,
    updateViewSimilarSize,
    updateViewSimilarColor,
    updateViewSimilarHoveredColor,
    updateViewSimilarFavorite,
    updateAddToBagError,
  } = useProductPanel();

  useEffect(() => {
    setViewSimilarProducts(productPanelData);
  }, [productPanelData, setViewSimilarProducts]);

  const isGuest = useRef(true);

  const handleClose = () => {
    removeBodyClass('vs-panel-open');
    dispatch(closeProductPanel());
  };

  const handleLooksProductOptionsChange = (index, type, id) => {
    if (type === 'size') {
      updateViewSimilarSize(id, index);
    } else if (type === 'color') {
      updateViewSimilarColor(id, index);
    }
  };

  const handleLooksProductOptionsHover = (index, type, id) => {
    if (type === 'color') {
      updateViewSimilarHoveredColor(id, index);
    }
  };

  const handleAddToBagError = (error, productId) => {
    updateAddToBagError(productId, error);
  };

  const handleClearError = (productId) => {
    updateAddToBagError(productId, '');
  };

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(() => {
        isGuest.current = false;
      });
  }, []);

  const toggleFavorite = async (product, isFavorite, isGuest) => {
    const { fastlyHost, id, headers } = await getRequestParams(isGuest, session);
    const productId = product?.id;
    const url = `${fastlyHost}/uca-favorites/v1/${isGuest ? 'guests' : 'accounts'}/${id}/products`;
    try {
      if (isFavorite) {
        await axios.delete(`${url}/${productId}`, { headers });
      } else {
        await axios.post(url, { value: productId }, { headers });
      }
      return !isFavorite;
    } catch (err) {
      return err;
    }
  };

  const handleToggleFavorite = async (product, isFavorite) => {
    const nextIsFavorite = await toggleFavorite(product, isFavorite, isGuest?.current);
    updateViewSimilarFavorite(product?.id, nextIsFavorite);
    if (typeof nextIsFavorite === 'boolean') dispatch(toggleProductPanelFavorite(product, nextIsFavorite));
  };

  const handleAddToBag = (product, selectedSku, skuMediaUrl, storeNumber) => {
    dispatch(addToBag(
      product,
      selectedSku,
      skuMediaUrl,
      storeNumber,
      (error) => updateAddToBagError(product?.id, error),
      true
    ));
  };

  const handleClickThrough = () => {
    panelTitle === QUICK_LOOK ? referralSourceClicked(
      '',
      '',
      {
        prev_page_link: 'plp_ql_panel',
        prev_link_type: 'product',
        cookie_name: 'om_prev_page',
      }
    ) : referralSourceClicked(
      '',
      '',
      {
        prev_page_link: 'plp_vs_panel',
        prev_link_type: 'product',
      }
    );
  };

  return (
    <ProductPanel
      isOpen={isProductPanelOpen}
      loading={isLoading}
      handleClose={handleClose}
      products={viewSimilarProducts}
      device={device}
      isServeProperSizedScene7PDP={isServeProperSizedScene7PDP}
      cloudinaryDprToggle={cloudinaryDprToggle}
      vendorQtyRestrictions={vendorQtyRestrictions}
      onlyXLeftThreshold={onlyXLeftThreshold}
      toggleFavorite={handleToggleFavorite}
      addToBag={handleAddToBag}
      showAddToBagError={handleAddToBagError}
      clearError={handleClearError}
      productOptionsOnHover={handleLooksProductOptionsHover}
      productOptionsOnClick={handleLooksProductOptionsChange}
      numberOfProducts={viewSimilarProducts?.length > 0 ? viewSimilarProducts.length : 2}
      title={panelTitle}
      errorMessage="Product info not available"
      shouldUseOriginalCanonicalURL={shouldUseOriginalCanonicalURL}
      handleClickThrough={handleClickThrough}
      pageId={pageId}
    />
  );
};

ProductPanelWrapper.propTypes = {
  device: PropTypes.object,
  isProductPanelOpen: PropTypes.bool,
  productPanelData: PropTypes.arrayOf(PropTypes.object),
  cloudinaryDprToggle: PropTypes.bool,
  isServeProperSizedScene7PDP: PropTypes.bool,
  onlyXLeftThreshold: PropTypes.number,
  vendorQtyRestrictions: PropTypes.shape({}),
  shouldUseOriginalCanonicalURL: PropTypes.bool,
};

ProductPanelWrapper.defaultProps = {
  isProductPanelOpen: false,
  productPanelData: [],
  device: {},
  cloudinaryDprToggle: false,
  isServeProperSizedScene7PDP: false,
  onlyXLeftThreshold: 2,
  vendorQtyRestrictions: {},
  shouldUseOriginalCanonicalURL: false,
};

const mapStateToProps = (state) => {
  return {
    isProductPanelOpen: get(state, 'productPanel.open'),
    isLoading: get(state, 'productPanel.loading', false),
    panelTitle: get(state, 'productPanel.title'),
    productPanelData: get(state, 'productPanel.products'),
    device: state.device,
    cloudinaryDprToggle: get(state, 'toggles.CLOUDINARY_DPR', false),
    isServeProperSizedScene7PDP: get(
      state,
      'toggles.SERVE_PROPER_SIZED_SCENE7_PDP',
      false
    ),
    onlyXLeftThreshold: get(state, 'onlyXLeftThresholds.defaultThreshold', 2),
    vendorQtyRestrictions: get(
      state,
      'productCatalog.vendorQtyRestrictions',
      {}
    ),
    shouldUseOriginalCanonicalURL: get(state, 'productPanel.shouldUseOriginalCanonicalURL', false),
    session: state.session,
    pageId: get(state, 'page.pageId', ''),
  };
};


export default connect(mapStateToProps)(ProductPanelWrapper);
