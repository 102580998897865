
const initialState = [];

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case 'SET_VIEW_SIMILAR_PRODUCTS':
      return action.payload.map((product) => ({
        ...product,
        quantity: 1,
      }));
    case 'SET_IS_COLOR_CHANGED':
      return {
        ...state,
        isColorChanged: action.payload,
      };
    case 'SET_HOVERED_COLOR':
      return {
        ...state,
        options: {
          ...state.options,
          hoveredColorIndex: action.payload,
        },
      };
    case 'SET_SELECTED_COLOR':
      return {
        ...state,
        options: {
          ...state.options,
          selectedColorIndex: action.payload,
        },
      };
    case 'SET_FAVORITE':
      return {
        ...state,
        isFavorite: action.payload,
      };
    case 'UPDATE_QUANTITY':
      return {
        ...state,
        quantity: parseInt(action.payload.toString().substr(0, 3), 10),
      };
    case 'SET_SELECTED_SIZE':
      return {
        ...state,
        options: {
          ...state.options,
          selectedSizeIndex: action.payload,
        },
      };
    case 'ADD_TO_BAG_ERROR':
      return {
        ...state,
        addToBagError: action.payload,
      };
    default:
      return state;
  }
};

export const productPanelReducer = (state = initialState, action) => {
  const { productId } = action?.meta || {};

  if (!productId) {
    return reducer(state, action);
  }

  const nextState = [...state];
  const index = nextState.findIndex(({ id }) => id === productId);

  if (index === -1) {
    return state;
  }

  nextState[index] = reducer(nextState[index], action);
  return nextState;
};
